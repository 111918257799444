<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 个人证书审核 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" allowClear/>
        <a-input placeholder="请输入手机号" v-model="mobile" allowClear/>
        <!-- <a-input placeholder="请输入身份证号" v-model="idcard" allowClear/> -->
        <a-select v-model="upcertType" placeholder="请选择证书类型" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">教师资格证</a-select-option>
          <a-select-option :value="2">学历证书</a-select-option>
          <a-select-option :value="3">认证行为治疗师</a-select-option>
        </a-select>
        <a-select v-model="auditStatus" placeholder="请选择审核状态" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">未审核</a-select-option>
          <a-select-option :value="2">已审核</a-select-option>
          <a-select-option :value="3">已驳回</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" :loading="loading" @click="search()"
          >搜索</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.certifId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="auditStatus" slot-scope="item">
          {{ item == 2 ? "已审核" : item == 3 ? "已驳回" : "未审核" }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="blueText">
            <span v-if="record.auditStatus == 1" @click="changitem(record)"
              >审核</span
            >
            <span v-if="record.auditStatus == 1">|</span>
            <span @click="showModal(text.picture)">查看</span>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 审核 -->
    <a-modal v-model="examine.isNumberShow" title="审核">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="5" class="a-row-left"
            ><span class="name">审核操作：</span></a-col
          >
          <a-col>
            <a-radio-group v-model="examine.examineValue">
              <a-radio style="width: 80px" :value="2">通过</a-radio>
              <a-radio :value="3">驳回</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <!-- <a-row class="a-row-item" v-show="examine.examineValue == 2">
          <a-col :span="5" class="a-row-left"
            ><span class="name">是否发送短信：</span></a-col
          >
          <a-col>
            <a-radio-group v-model="examine.messageValue">
              <a-radio style="width: 80px" :value="1">否</a-radio>
              <a-radio :value="2">是</a-radio>
            </a-radio-group>
          </a-col>
        </a-row> -->
        <a-row
          class="a-row-item"
          v-show="examine.examineValue == 3"
        >
         <!-- && examine.messageValue == 2 -->
          <a-col :span="5" class="a-row-left"
            ><span class="name">驳回原因：</span></a-col
          >
          <a-col>
            <a-textarea
              v-model="examine.content"
              style="width: 70%"
              placeholder="请输入驳回原因"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="verifyLoad" @click="seloperation()">确认</a-button>
        <a-button @click="examine.isNumberShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 查看证书图片 -->
    <a-modal v-model="visible" title="证书图片">
      <div class="viscimg">
        <img :src="viscimg" alt="" />
      </div>
      <template slot="footer">
        <a-button @click="visible = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "用户名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "证书类型",
    align: "center",
    dataIndex: "upcertType",
  },
  {
    title: "审核状态",
    align: "center",
    dataIndex: "auditStatus",
    scopedSlots: { customRender: "auditStatus" },
  },
  {
    title: "审核账号",
    align: "center",
    dataIndex: "auditor",
  },
  {
    title: "操作",
    align: "center",
    width: "220px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      verifyLoad:false,
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNum: 1, //页码
      pageSize: 10, // 页数

      auditStatus: undefined, //审核状态：1.未审核，2.已审核，3.已驳回
      name: "",
      mobile: "",
      idcard: "",
      upcertType: undefined, //证书类型：1.教师资格证 2.学历证书 3.认证行为治疗师

      // 弹窗内容
      examine: {
        examineValue: 1,
        messageValue: 2,
        isNumberShow: false,
        content: "", //审核内容
        certifName: "", //证书类型
      },
      // 待审核的id
      examineId: "",

      // 查看图片
      visible: false,
      viscimg: "",
    };
  },
  // 事件处理器
  methods: {
    // 搜索
    search() {
      this.pageNum = 1;
      this.getdataList();
    },
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/user-certif/personal/audit/list",
        method: "get",
        params: {
          upcertType: this.upcertType == 0 ? "" : this.upcertType,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          auditStatus: this.auditStatus == 0 ? "" : this.auditStatus,
          name: this.name,
          mobile: this.mobile,
          idcard: this.idcard,
        },
      }).then((res) => {
        this.loading = false
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    // 开始审核
    changitem(e) {
      this.examine.isNumberShow = true;
      this.examine.certifName = e.upcertType;
      this.examineId = e.certifId;
    },
    // 通过或驳回
    seloperation() {
      this.verifyLoad = true
      this.$ajax({
        url: "/hxclass-management/user-certif/manage/personal/audit-reject",
        method: "put",
        params: {
          auditStatus: this.examine.examineValue,
          certifId: this.examineId,
          auditReason: this.examine.content, //审核原因
        },
      }).then((res) => {
        this.verifyLoad = false
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getdataList();
          this.examine.isNumberShow = false;
        } else {
          this.$message.error(res.message);
          this.examine.isNumberShow = false;
        }
      });
    },

    // 查看图片
    showModal(e) {
      this.viscimg = e;
      this.visible = true;
    },
    // handleOk(e) {
    // console.log(e);
    //   this.visible = false;
    // },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.all_content_box {
  .a-row-item {
    margin-bottom: 20px;
    .a-row-left {
      margin-right: 10px;
    }
    .name {
      width: 100%;
      display: inline-block;
      text-align: right;
    }
    .right {
      width: 100%;
    }
  }
}

.viscimg {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
